<template>
	<div class="login">
		{{message}}
	</div>
</template>

<script>
	import {defineComponent,onMounted,computed} from 'vue'
	import login from "@/utils/login"
	import {useStore} from "vuex"
	import {useRouter} from "vue-router"
	export default defineComponent({
		name: 'login',
		setup(props,ctx){
			const store = useStore();
			const router = useRouter();
			const message = computed(()=>{
				return store.state.app.message
			});
			onMounted(()=>{
				login.getAuthInfo(()=>{
					login.requestAuthCode(()=>{
						let query = {

						};
						if(sessionStorage.getItem('cf-tab')){
							query.tab = sessionStorage.getItem('cf-tab');
							sessionStorage.removeItem('cf-tab');
						}
						router.push({path:'/home',query:query})
					})
				});
			});
			return {
				message
			}
		},
		components: {}
	})
</script>
<style scoped lang="less">
	.login{
		padding: 20px;
	}
</style>
